import { AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, LinearProgress, Typography, Box } from "@mui/material";
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useMemo, useState } from "react";
import { parseError, numberFormat } from "../../utils";
import { useSnackbar } from "../Snackables";
import { useLimitedReleasePurchase } from "./hooks";
import { ILimitedReleaseItem, ILimitedReleaseResult } from "./types";
import { KeyedMutator } from "swr";
import { Link } from "react-router-dom";

interface ILimitedReleaseConfirmProviderProps extends PropsWithChildren {
  id: string;
  balance: number;
  nftAddress: string;
  items: ILimitedReleaseItem[];
  mutate: KeyedMutator<ILimitedReleaseResult>;
}

type IOpenState = 'confirm' | 'nowallet' | '';

interface ILimitedReleaseConfirmContext {
  id: string;
  balance: number;
  nftAddress: string;
  mutate: KeyedMutator<ILimitedReleaseResult>;
  open: IOpenState;
  selected?: ILimitedReleaseItem;
  setConfirmationOpen: (selected: ILimitedReleaseItem) => void;
  setOpen: Dispatch<SetStateAction<IOpenState>>;
}

const LimitedReleaseConfirmContext = createContext<null | ILimitedReleaseConfirmContext>(null)

export const useLimitedReleaseConfirm = () => {
  const ctx = useContext(LimitedReleaseConfirmContext)

  if (!ctx) {
    throw new Error("useLimitedReleaseConfirm must be used within a LimitedReleaseConfirmProvider")
  }

  return ctx
}

export const LimitedReleaseConfirmProvider = ({ children, items, ...props }: ILimitedReleaseConfirmProviderProps) => {
  const [open, setOpen] = useState<IOpenState>('')
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
  const selected = useMemo(() => items.find((item) => item.id === selectedId), [items, selectedId])
  const { setSnackbar } = useSnackbar()[1];

  const setConfirmationOpen = (selected: ILimitedReleaseItem) => {
    if (props.balance < selected.amount) {
      setSnackbar({
        autoHideDuration: 6000,
        message: "Insufficient funds",
        severity: "error",
      });
      return
    }
    setSelectedId(selected.id)
    setOpen('confirm')
  }

  return (
    <LimitedReleaseConfirmContext.Provider value={{ open, selected, setConfirmationOpen, setOpen, ...props }}>
      {children}
      <LimitedReleaseConfirmDialog />
    </LimitedReleaseConfirmContext.Provider>
  )
}

const LimitedReleaseConfirmDialog = () => {
  const { id, mutate, nftAddress, balance, open, selected, setOpen } = useLimitedReleaseConfirm()
  const { trigger, isMutating } = useLimitedReleasePurchase(id);
  const { setSnackbar } = useSnackbar()[1];

  const handleCancel = () => {
    if (!nftAddress) {
      setOpen('nowallet');
    } else {
      setOpen('');
    }
  }

  const handleClose = () => {
    setOpen('')
  }

  const handlePurchase = async () => {
    try {
      if (!selected) return

      if (balance < selected.amount) {
        throw new Error("Insufficient funds")
      }
      
      const result = await trigger({ itemId: selected.id });

      if (result.release) {
        await mutate(
          { release: result.release, balance: result.balance, nftAddress: result.nftAddress },
          { revalidate: false }
        );
      }

      if (result.message) {
        throw new Error(result.message);
      }

      setSnackbar({
        autoHideDuration: 10000,
        message: (
          <>
            <AlertTitle>Purchase Successful</AlertTitle> {selected.name} has been
            added to your account <Link to="/profiles">account</Link>.
          </>
        ),
        severity: "success",
      });
      setOpen('');
    } catch (err) {
      setSnackbar({
        autoHideDuration: 6000,
        message: parseError(err),
        severity: "error",
      });
    }
  };

  return (
    <>
    <Dialog open={open === 'confirm'} onClose={handleCancel} fullWidth maxWidth="xs">
      <DialogTitle>Confirm Purchase</DialogTitle>
      {/* <Divider /> */}
      <DialogContent>
        <Box mb={2}>
          <TextRow label="Balance">{numberFormat(balance, 0)} COIN</TextRow>
          <TextRow label="Cost">({numberFormat(selected?.amount ?? 0, 0)} COIN)</TextRow>
          <TextRow label="Adjusted">{numberFormat(balance - (selected?.amount ?? 0), 0)} COIN</TextRow>
        </Box>
        <DialogContentText>Are you sure you want to purchase <strong>{selected?.name}</strong>?</DialogContentText>
        {
          nftAddress ? (
            <Tooltip title={<Typography>{nftAddress}</Typography>}>
              <Typography variant="subtitle2">Delivering to: {nftAddress.slice(0, 6)}...{nftAddress.slice(-6)}</Typography>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <Typography>
                  You don&lsquo;t currently have an NFT Wallet set on your account. Not to worry though, you can use the shared COIN NFT address
                  and transfer it to your own address later.
                </Typography>
              }
            >
              <Typography variant="subtitle2">
                Delivering to: COIN Default NFT Address <span>ⓘ</span>
              </Typography>
            </Tooltip>
          )}
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handlePurchase} variant="contained" color="primary" disabled={selected?.sold || isMutating} sx={{ position: 'relative' }}>
          {isMutating ? <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} /> : null}
          <span style={{ color: 'white' }}>Confirm</span>
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={open === 'nowallet'} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>Purchase Canceled</DialogTitle>
      <DialogContent>
        <DialogContentText>Not to worry, you can return here after setting the NFT address in app.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
    </>
  )
}

const TextRow = ({ label, children }: PropsWithChildren<{ label: string }>) => {
  return <Box display="flex" justifyContent="space-between">
    <Typography><strong>{label}</strong></Typography>
    {typeof children === 'string' ? <Typography>{children}</Typography> : children}
  </Box>
}