import SoldIcon from "@mui/icons-material/MoneyOff";
import {
  AppBar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { capitalize, groupBy, uniq } from "lodash";
import { useState } from "react";
import { useBeforeUnload, useParams } from "react-router-dom";
import { numberFormat } from "../../utils";
import { StickyCountdown } from "./Countdown";
import { useLimitedRelease } from "./hooks";
import { LimitedReleaseCard } from "./Item";
import { LimitedReleaseConfirmProvider } from "./Confirm";
import { useTheme } from "@mui/material/styles";
import { Footer } from '../Footer/Main'

const fieldStyle = {
  bgcolor: "background.paper",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.87)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "primary.main",
  },
  "&.Mui-disabled": {
    color: "white",
    "-webkit-text-fill-color": "white",
  },
  color: "white",
};

const LimitedReleaseDrops = () => {
  const params = useParams<{ id: string }>();
  const { isLoading, data, mutate } = useLimitedRelease(params.id as string);
  const [selectedGroup, setSelectedGroup] = useState("all");

  const release = data?.release;
  const groupKey = release?.groupBy ?? "";
  const groupName = capitalize(groupKey);
  const groupNames =
    !groupName || groupName.endsWith("s") ? groupName : `${groupName}s`;
  const groupValues = uniq<string>(
    release?.items.map((item) => item[groupKey])
  );

  const groups = groupBy(release?.items, groupKey);

  const balance = data?.balance ?? 0;
  const nftAddress = data?.nftAddress ?? '';

  const filteredGroups =
    selectedGroup === "all" ? groupValues : [selectedGroup];

  const handleGroupChange = (event: SelectChangeEvent) => {
    setSelectedGroup(event.target.value);
  };

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <LimitedReleaseConfirmProvider id={params.id as string} items={release?.items ?? []} mutate={mutate} balance={balance} nftAddress={nftAddress}>
      <StickyCountdown takeoverMessage={release?.soldOut ? 'Limited release sold out!' : ''} startDate={release?.startDate} endDate={release?.endDate} isLoading={isLoading} />
      <Box
        sx={{
          bgcolor: "background.default",
          minHeight: "100vh",
          color: "text.primary",
          py: 6,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            align="center"
            sx={{ mb: 2, fontWeight: "bold" }}
          >
            {isLoading ? (
              <Skeleton variant="text" style={{ margin: "auto" }} width="80%" />
            ) : (
              release?.title
            )}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            align="center"
            sx={{ mb: 4, color: "text.secondary" }}
          >
            {isLoading ? (
              <Skeleton variant="text" style={{ margin: "auto" }} width="100%" />
            ) : (
              release?.subtitle
            )}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
            <FormControl>
              <Typography
                variant="caption"
                sx={{ mb: 1, color: "text.secondary" }}
              >
                COIN Balance
              </Typography>
              <TextField
                value={isLoading ? "" : numberFormat(balance)}
                sx={fieldStyle}
                disabled
              />
            </FormControl>

            <FormControl>
              <Typography
                variant="caption"
                sx={{ mb: 1, color: "text.secondary" }}
              >
                Filter by {groupName}
              </Typography>
              <Select
                value={selectedGroup}
                onChange={handleGroupChange}
                displayEmpty
                sx={fieldStyle}
              >
                <MenuItem value="all">All {groupNames}</MenuItem>
                {groupValues.map((value) => (
                  <MenuItem key={value} value={value}>
                    {capitalize(value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {filteredGroups.map((group) => (
            <Box key={group} sx={{ mb: 8 }}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                sx={{ color: "primary.main", fontWeight: "bold", mb: 3 }}
              >
                {group} Tier
              </Typography>
              <Divider sx={{ mb: 4, bgcolor: "primary.main", opacity: 0.2 }} />
              <Grid container spacing={isMdUp ? 4: 2}>
                {groups[group].map((item) => (
                  <Grid item key={item.id} xs={6} md={4} lg={3} mb={2}>
                    <LimitedReleaseCard item={item} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Container>
      </Box>
      <Footer />
    </LimitedReleaseConfirmProvider>
  );
};

export default LimitedReleaseDrops;
